import React from 'react';
import {
  DoubleImage,
  Link,
  List,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({}) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Text"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Text"
      />

      <Section title="Resizing Text">
        <SectionSubhead>Low-vision Users</SectionSubhead>
        <Paragraph>
          <Link href="https://www.who.int/news-room/fact-sheets/detail/blindness-and-visual-impairment">
            About 29% of the world's population
          </Link>{' '}
          is affected by some form of low vision impairment that may not be
          correctable. This includes degraded eyesight in one or both eyes in
          the form of tunnel vision, blurred vision, blind spots, or eye
          injuries. It's our responsibility to think about a variety of ways we
          may be able to maximize the usability and readibility of our content,
          including how text{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/resize-text.html">
            can be resized to up to 200%
          </Link>{' '}
          (except for captions and images of text) without loss of meaning or
          functionality.
        </Paragraph>
        <DoubleImage
          textOne="An interface viewed with blurred vision."
          imgOneFilePath="accessibility/example-blurred-vision.png"
          textTwo="An interface viewed with loss of central vision."
          imgTwoFilePath="accessibility/example-center-vision-loss.png"
        />
        <Paragraph>
          Uniform strives to comply with{' '}
          <Link href="https://www.w3.org/TR/WCAG21/#distinguishable">
            WCAG guidelines for Distinguishable content
          </Link>
          , especially contrast and text resizing. Our components in isolation
          can respond to increased font sizes dictated by the browser. Larger or
          more complex interfaces may require additional testing to ensure text
          doesn't accidentally truncate or move out of view in an unexpected way
          when the text size increases.
        </Paragraph>
        <SectionSubhead>Recommended Steps</SectionSubhead>
        <List type="unordered">
          <li>
            Use plugins like{' '}
            <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
              Stark for Figma
            </Link>
            , or the{' '}
            <Link href="https://bit.ly/3ynLRSY">Funkify Chrome plugin</Link> to
            view your interface in design hand-off.
          </li>
          <li>
            Before dev handoff, test how the interface might look with text
            sizes increased to 200% of their normal value using the{' '}
            <Link href="https://www.figma.com/community/plugin/892114953056389734/Text-Resizer---Accessibility-Checker">
              Text Resizer plugin
            </Link>
            .
          </li>
          <li>
            Work with QA to include text resizing as part of the A/C for new
            features. Use browser settings to verify text has
            increased/decreased in size and that no text or content is hidden
            from view.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
